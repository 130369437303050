import React, { Component } from 'react';
import ReduxProvider from 'ReduxProvider';
import ApiUtils from 'Utils/apiUtil';
import ReactOnRails from 'react-on-rails';
import { Field, reduxForm } from 'redux-form';
import { renderFormField } from 'RenderField/index';
import { formErrorsValidations } from 'Utils/validator';
import { NotificationManager } from 'react-notifications';

const requiredFieldsArray = [
  {
    id: 'email',
    validation: [
      {
        rule: 'email',
        message: 'Email is not valid.',
      },
      {
        rule: 'required',
        message: 'Email is required.',
      },
      {
        rule: 'empty',
        message: 'Enter valid email.',
      },
    ],
  },
];

const validate = values => formErrorsValidations(values, requiredFieldsArray);

export class ForgotPassword extends Component {
  constructor(props, _railsContext) {
    super(props);
    this.state = {
      isSubmitted: false,
    };
  }

  onHandleSubmit = values => {
    const csrfToken = ReactOnRails.authenticityToken();

    const form = new FormData();
    form.append('user[email]', values.email);
    this.setState({ isSubmitted: true });
    ApiUtils.forgotPassword(csrfToken, form)
      .then(response => {
        if (parseInt(response.status, 10) === 200) {
          NotificationManager.success('Password instructions sent successfully');
          window.location = '/';
        }
        this.setState({ isSubmitted: false });
      })
      .catch(err => {
        if (parseInt(err.response.status, 10) === 500 && err.response.data[0] === 'Email not found') {
          NotificationManager.error(err.response.data[0]);
        } else {
          NotificationManager.error('Something went wrong!!');
        }
        this.setState({ isSubmitted: false });
      });
  };

  render() {
    const { handleSubmit } = this.props;
    const { isSubmitted } = this.state;
    return (
      <div className="align-items-center position-relative u-space-5 font-style">
        <div className="container">
          <div className="no-gutters">
            <div className="offset-md-2 offset-lg-2 offset-xl-3">
              {/* <!-- Form --> */}
              <form className="js-validate mt-5" onSubmit={handleSubmit(this.onHandleSubmit)}>
                {/* <!-- Title --> */}
                <div className="mb-7">
                  <h1 className="h3 font-weight-normal mb-0">
                    Forgot your <span className="font-weight-bold">password?</span>
                  </h1>
                  <p>Enter your email address below and we&apos;ll get you back on track.</p>
                </div>
                {/* <!-- End Title --> */}

                {/* <!-- Input --> */}
                <div className="js-form-message mb-4">
                  <label className="h6 small d-block text-uppercase">Email address</label>
                  <Field
                    name="email"
                    className="form-control"
                    displayLabel={false}
                    component={renderFormField}
                    type="text"
                    placeholder="Email"
                  />
                </div>
                {/* <!-- End Input --> */}

                {/* <!-- Button --> */}
                <div className="row align-items-center mb-5">
                  <div className="col-4 col-sm-6 pull-up-layer">
                    <a className="small u-link-muted" href="/users/confirmation/new">
                      Didn&apos;t receive confirmation instructions?
                    </a>
                  </div>

                  <div className="col-8 col-sm-6 text-right pull-up-layer">
                    <button
                      type="submit"
                      className="btn btn-primary u-btn-primary transition-3d-hover"
                      disabled={isSubmitted}
                    >
                      Request Reset Link
                    </button>
                  </div>
                </div>
                {/* <!-- End Button --> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const ForgotPwdComponent = reduxForm({ form: 'forgot-password', validate })(ForgotPassword);

export default (props, _railsContext) => (
  <ReduxProvider {...{ ...props, ...{ resetCounter: true } }}>
    <ForgotPwdComponent />
  </ReduxProvider>
);
